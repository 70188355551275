import React from "react"
import { PageProps, graphql } from "gatsby"

import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import RichTextRenderer from "../elements/RichTextRenderer"
import HeroMazeDefault from "../components/hero-default"
import Widget from "../components/widgets"
import ContentfulLink from "../elements/ContentfulLink"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import HomePageTeams from "../components/home-teams"
import LocationListingCards from "../components/location/listing-cards"

export interface ILocation {
  title: string
  seoTitle: string
  seoDescription: string
  isInTeam: boolean
  headingOne: string
  headingTwo: any
  headingDescription: any
  locationCards: any
  region: Array<any>
}

interface ILocationProps {
  contentfulCareersLocationListing: ILocation
  contentfulCareersComponentCta: any
}

interface ICareersHomePageContext {
  id: string
  slug: string
  locale: string
}

const JobListing: React.FC<PageProps<ILocationProps, ICareersHomePageContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersLocationListing.seoTitle || data.contentfulCareersLocationListing.title,
    description: data.contentfulCareersLocationListing.seoDescription,
  }
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout seoData={seoData}>
        <HeroMazeDefault
          subheading={data.contentfulCareersLocationListing.headingOne}
          heading={data.contentfulCareersLocationListing.headingTwo}
          content={data.contentfulCareersLocationListing.headingDescription}
          className="teams-listing"
        />
        {data.contentfulCareersLocationListing.isInTeam ? (
          <HomePageTeams teams={data.contentfulCareersLocationListing.locationCards} />
        ) : (
          <LocationListingCards locationCards={data.contentfulCareersLocationListing.locationCards} />
        )}
        {data.contentfulCareersLocationListing.region &&
          data.contentfulCareersLocationListing.region.map((widgetData, i) => <Widget key={i} {...widgetData} />)}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default JobListing

export const query = graphql`
  query ($id: String!) {
    contentfulCareersLocationListing(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      isInTeam
      headingOne
      headingTwo {
        ...RichTextField
      }
      headingDescription {
        ...RichTextField
      }
      locationCards {
        ...ComponentCard
      }
      region {
        ...ComponentCTA
      }
    }
  }
`
