import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import ContentfulLink from "../../../elements/ContentfulLink"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import "./style.scss"

const LocationListingCards: React.FC<{ locationCards: any }> = ({ locationCards }) => {
  return (
    <div className="clear grid grid-listing">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 location-listing-cards-container">
            {locationCards.map((location: any, i: number) => (
              <div className="col-sm-12 col-md-6 col-lg-4" key={i}>
                <div className="card card-grid clearfix">
                  <div className="card-img location-listing-card-img">
                    <div className="overflow-hidden">
                      {location.image && (
                        <GatsbyImage
                          image={location.image.fluid}
                          alt={location.image.title || ""}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <h2 className="card-title location-listing-cards-title">
                      <ContentfulLink
                        className="stretched-link location-listing-contentful-link"
                        link={location.links[0]}
                      >
                        {location.heading}
                      </ContentfulLink>
                    </h2>
                    <span className="btn-link location-listing-button-link">{location.links[0].label}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationListingCards
