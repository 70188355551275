import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import RichTextRenderer from "../../elements/RichTextRenderer"
import ContentfulLink from "../../elements/ContentfulLink"
import "./style.scss"
import Carousel from "../../elements/Carousel"

interface HomePageTeams {
  teamsIntro?: {
    json: string
  }
  teams: Array<any>
}

const HomePageTeams: React.FC<HomePageTeams> = ({ teamsIntro, teams }) => {
  return (
    <section className="teams">
      {teamsIntro && (
        <div className="container teams-intro">
          <div className="row">
            <div className="col-lg-12 text-lg-center">
              <>
                <RichTextRenderer json={teamsIntro} />
              </>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <Carousel
          element="js-team-carousel"
          showArrows
          options={{
            type: "slider",
            perView: 3,
            rewind: false,
            breakpoints: {
              600: {
                perView: 1,
                // peek: { before: 0, after: 50 },
              },
              992: {
                perView: 2,
              },
              1200: {
                perView: 3,
              },
            },
          }}
        >
          {teams.map(team => (
            <li className="card card-grid clearfix" key={team.title}>
              <div className="card-img">
                <div className="overflow-hidden">
                  {team.image && <GatsbyImage image={team.image.fluid} alt={team.image.title || ""} />}
                </div>
              </div>
              <div className="card-body">
                <h2 className="card-title">
                  <ContentfulLink
                    className="stretched-link"
                    link={{
                      ...team.links[0],
                      label: team.heading,
                    }}
                  />
                </h2>
                <ContentfulLink link={team.links[0]} className="btn-link" />
              </div>
            </li>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default HomePageTeams
